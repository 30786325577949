<template>
    <transition name="fade">
        <div class="movies page">


            <div class="featured" v-if="vod[0] && vod[0].vods && vod[0].vods.length > 0">
                <h1> On Demand</h1>

                <Transition name="fade">
                    <div class="cover" v-show="!moving"><img :src="vod[0].vods[0].backdrop_path" ref="cover"/></div>
                </Transition>

                <Flicking ref="flicking" :options="{ align: 'prev', mode: 'strict', class: 'features', circularFallback: 'bound' }" class="features" @move-end="updateBG($event)"  @move-start="$event => startBG($event)" :plugins="plugins">
                    <router-link v-for="(v, i) in vod[0].vods" :to="`/${v.type}/${v.id}`" :key="i" class="pannel" :id="i" v-ripple>
                        <div class="feature" v-ripple>
                            <div>
                                <div class="poster"><img :src="v.poster_path"/></div>
                                <h3> {{ v.name || v.title }} </h3>
                            </div>
                        </div>
                </router-link>

                <div slot="viewport" class="flicking-pagination"></div>

                </Flicking>
            </div>

            <div style="margin-top:75px">

                <div v-if="saved.length > 0" class="content">
                    <h2> Your Library</h2>

                    <div class="content-row" >
                        <div v-for="(media, i) in saved" :key="i">
                            <Saved :saved="media" />
                        </div>
                    </div>
                </div>


                <div v-if="history.length > 0" class="content">
                    <h2> Continue Watching</h2>

                    <div class="content-row" >
                        <div v-for="(media, i) in history" :key="i">
                            <History :history="media" />
                        </div>
                    </div>
                </div>
            </div>



          <transition name="fade">
              <div class="content">

                <div class="content-g" v-for="(v, k) in vod" :key="k">
                    <h2 v-if="v.name"> {{ v.name }} </h2>
                    <div class="content-row" v-if="v.name">
                        <div v-for="(media, i) in v.vods" :key="i" >
                            <Movie :media="media" ref="media" :list="true" :display="true" v-ripple/>
                        </div>
                    </div>
                </div>
              </div>
          </transition>
      </div>

    </transition>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";

import { Perspective, Pagination } from "@egjs/flicking-plugins";
import History from "@/components/VodHistory.vue";
import Saved from "@/components/Saved.vue";


// @ is an alias to /src
const Movie = () => import('@/components/Movie.vue')

export default {
  name: 'Movies',

  components: {
    Movie,
    History,
    Saved,
    Flicking: Flicking

  },

  computed: {

  },

  data: function(){
    return {
        plugins: [new Perspective({ rotate: 0.25 }), new Pagination({ type: 'scroll' })],

        vod: [],
        history: [],
        saved: [],
        moving: false,

        activeFeature: {id: '', name: 'loading...'},
        activeGenre: {id: '', name: 'loading...'},

        //move to OC
        genres: [{"id":28,"name":"Action"}, {"id":12,"name":"Adventure"}, {"id":16,"name":"Animation"}, {"id":35,"name":"Comedy"}, {"id":80,"name":"Crime"}, {"id":99,"name":"Documentary"}, {"id":18,"name":"Drama"}, {"id":10751,"name":"Family"}, {"id":14,"name":"Fantasy"}, {"id":36,"name":"History"}, {"id":27,"name":"Horror"}, {"id":10402,"name":"Music"}, {"id":9648,"name":"Mystery"}, {"id":10749,"name":"Romance"}, {"id":878,"name":"Science Fiction"},  {"id":53,"name":"Thriller"}, {"id":10752,"name":"War"}, {"id":37,"name":"Western"}, {"id":10759,"name":"Action & Adventure"},{"id":16,"name":"Animation"},{"id":35,"name":"Comedy"},{"id":80,"name":"Crime"},{"id":99,"name":"Documentary"},{"id":18,"name":"Drama"},{"id":10751,"name":"Family"},{"id":10762,"name":"Kids"},{"id":9648,"name":"Mystery"},{"id":10764,"name":"Reality"},{"id":10765,"name":"Sci-Fi & Fantasy"},{"id":10766,"name":"Soap"},{"id":10767,"name":"Talk"},{"id":10768,"name":"War & Politics"},{"id":37,"name":"Western"}],
        features: [ { "name": "Keanu Reeves", "id": 6384 }, { "name": "Angelina Jolie", "id": 11701 }, { "name": "Denzel Washington", "id": 5292 }, { "name": "Scarlett Johansson", "id": 1245 }, { "name": "Quentin Tarantino", "id": 138 }, { "name": "Robert De Niro", "id": 569 }, { "name": "Henry Cavill", "id": 73968 }, { "name": "Dwayne Johnson", "id": 18918 }, { "name": "Joaquin Phoenix", "id": 73421 }, { "name": "Morgan Freeman", "id": 192 }, { "name": "Chloë Grace Moretz", "id": 56734 }, { "name": "Tom Holland", "id": 1136406 }, { "name": "Tom Hardy", "id": 2524 }, { "name": "Brad Pitt", "id": 16869 }, { "name": "Anne Hathaway", "id": 1813 }, { "name": "Wes Anderson", "id": 5655}, { "name": "Christopher Nolan", "id": 525 }, { "name": "Steven Spielberg", "id": 488 }, { "name": "Bradley Cooper", "id": 51329 }, { "name": "Will Smith", "id": 2888 } ],

        letLoadingResults: false
    }
  },

  activated: function () {
    this.getSaves()
  },

  mounted: async function(){

    this.activeGenre = this.genres[Math.floor(Math.random() * this.genres.length)];
    this.activeFeature = this.features[Math.floor(Math.random() * this.features.length)];

    const t = [
        { section: "", query: 'sort_by=popularity.desc&vote_count.gte=1000' },
        { section: "Popular", query: 'sort_by=popularity.desc&vote_count.gte=1000', page: 2 },

        { section: "Critcally Acclaimed", query: `sort_by=vote_average.desc&vote_count.gte=2000` },

        { section: 'New and Noteworthy', query: 'sort_by=primary_release_date.desc&vote_count.gte=100' },
        { section: 'More to love', query: 'sort_by=primary_release_date.desc&vote_count.gte=100', page: 2 },
        { section: 'Find Something New', query: 'sort_by=primary_release_date.desc&vote_count.gte=100', page: 3 },

        { section: "Box Office Hits", query: 'sort_by=revenue.desc', tv: false},
        { section: "Star Studded Casts", query: 'sort_by=revenue.desc', page: 2, tv: false },
        { section: "Instant Classics", query: 'sort_by=revenue.desc', page: 3 , tv: false},

        { section: `Best of ${this.activeGenre.name}`, query: '?with_genres=' + this.activeGenre.id, tv: false},
        { section: `Best of ${this.activeFeature.name}`, query: 'with_people=' + this.activeFeature.id, tv: false},

        { section: "Hidden Gems", query: 'vote_count.lte=100&vote_average.gte=7&popularity.lte=25', page: 1 },
        { section: "Fan Favorites", query: 'vote_count.lte=100&vote_average.gte=7&popularity.lte=25', page: 2 },


        { section: "Trending", query: 'sort_by=primary_release_date.desc&vote_count.gte=25'},

        { section: 'New & Upcoming Releases', query: 'sort_by=primary_release_date.desc'},

    ]

    for(const x of t){
        await this.loadSection(x.section, x.query, x.page, x.tv)
    }


  },

  methods: {
    async getSaves(){
        const saved  = await fetch(process.env.VUE_APP_SERVER + `/save?group=od`)
        const savedRes =  await saved.json()

        this.saved = savedRes.saves
        this.history = savedRes.history

    },
    startBG(){
        this.moving = true
    },
    updateBG(){
        this.$nextTick(() => {
            this.$refs.cover.src = this.vod[0].vods[this.$refs.flicking.currentPanel.index].backdrop_path
            this.$nextTick(() => {
                this.moving = false
            })
        })

    },

    async loadSection(name, query, page, tv){
        await this.loadMedia(name, query,  page, tv)
    },


    async loadMedia(name, query, page=1, tv=true){
        let media = []
        const movieresponse  = await fetch(process.env.VUE_APP_SERVER + `/discover`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                page: page,
                query: query,
                type: 'movie',
            })
        })
        const movieData =  await movieresponse.json()
        media = [...movieData.media]

        if(tv){
            const showresponse  = await fetch(process.env.VUE_APP_SERVER + `/discover`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    page: page,
                    query: query,
                    type: 'tv',
                })
            })
            const showData =  await showresponse.json()
            media = [...media, ...showData.media]
        }
        this.vod.push({name: name, vods: window.shuffle(media)})
        return
    }

  }
}
</script>

<style>
    .flicking-pagination.flicking-pagination-scroll{
        position: relative;
    }
    .flicking-pagination{
        position: absolute;
        bottom: 60px;
        left: calc(100vw - 100px);

    }
    .flicking-pagination-bullet{
        background-color: white!important;
        opacity: 0.1;
    }

    .flicking-pagination-bullet-active{
        opacity: 1;
    }

</style>
<style scoped lang="scss">

.movies{
    position: relative;
    width: 100vw;
    height: auto;
}
.featured{
    height: 60vh;
    min-height: 400px;
    position: relative;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    // overflow: hidden;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    width: 100vw;
    a{
    color: white;

    }
    .flicking-viewport{
        height: 100%;
        padding-top: 150px;
        transition: none;
    }

    .features{
        padding-left: 10vw;
        .feature{
            position: relative;
            min-width: 70vw;
            width: 70vw;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-shadow: 0 0px 7px rgba(black, 0.5);
        }

    }
    .poster{
        height: 50vh;
        min-height: 300px;
        img{
            height: 100%;
            border-radius: 15px;
            width: auto;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
        }

    }
    h3{
        font-size: 9px;
        text-transform: uppercase;
        max-width: 250px;
        letter-spacing: 3px;
        padding-top: 10px;
        position: relative;
        left: -15px;

    }
    .cover{
        position: absolute;
        width: 110%;
        left: -5%;
        top: 0;
        height: 110%;
        filter: blur(20px);
        z-index: -2;
        img{
            height: 100%;
            min-width: 110vw;
            object-fit: cover;
        }
    }
    h1{
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 20px;
        margin:0;
        margin-top: 20px;
        font-weight: bold;
        text-shadow: 0 0px 7px rgba(black, 0.5);
        z-index: 99999;
    }

}
.content{
    position: relative;
    z-index: 2;
    h2{
        font-size: 14pt;
        padding: 20px;
        margin-bottom: 0px;
        margin-top: 0;
        position: relative;
        svg{
            position: relative;
            width: 20px;
            top: 4px;
        }
    }

}

</style>
