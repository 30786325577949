<template>
    <router-link :to="'/' + history.details.contentType + '/' + history.id" class="history" v-ripple :style="{'background-image': `url(${ history.details.img || history.logos[history.logos.length-1] }` }" :class='size'>
      <div>
          <p> {{history.name}} </p>

          <span v-if='history.details.contentType == "tv"'> 
              <small><b>SEASON</b> {{history.details.season}}</small>
              <small><b>EPISODE</b> {{history.details.episode}}</small>
          </span>
      </div>
    </router-link>
</template>

<script>

export default {
  name: 'history',
  props: {
    history: Object,
    size: {
        type: String,
        default: function(){ return ''}
    }
  },

  computed: { },

  data: function(){
      return {}
  },

  methods: {

  },

  mounted: function(){
  }
}
</script>

<style scoped lang="scss">
.history{
    display: block;
    position: relative;
    border-radius: 8px;
    margin-right: 20px;
    background-size: cover;
    background-position: center;
    width: 300px;
    height: 200px;
    color: white;
    overflow: hidden;

    div{
        display: flex;
        position: absolute;
        bottom: 0;
        min-height: 60px;
        width: 100%;
        background: rgba(0,0,0,0.5);
        padding: 10px;
        box-sizing: border-box;
        backdrop-filter: blur(10px);
        justify-content: center;
        flex-direction: column;
        p{
            margin: 0;
        }
        small{
            font-size: 9px;
            margin-right: 10px;
            b{
                font-weight: bold;
                letter-spacing: 1px;
                color: red;
            }
        }
    }
}
</style>
